.app-btn {
  color: #0069d9;
  fill: #0069d9;

  background-color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 10px 20px;
  //font-family: "BentonSans";
  font-size: 16px;
  border-radius: 0;
  border: 2px solid #0069d9;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.3);
  width: 300px;
  height: 50px;

  cursor: pointer;

  &:hover {
    color: $color-white;
    fill: $color-white;

    background-color: #0069d9;
  }
}

.default-button {
  color: white;
  font-size: 1em;
  background-color: rgb(78, 139, 229);
  border-radius: 0;
  border: none;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.3);
  width: 300px;
  height: 50px;
  font-weight: 500;

  &:hover {
    color: white;
    background-color: rgb(47, 121, 233);
  }
  @include respond(phone) {
    width: 200px;
    height: 30px;
    font-size: 0.8em;
  }

  &--big-button {
    height: 70px !important;
  }

  &:active {
    background-color: rgb(78, 139, 229) !important;
    box-shadow: none !important;
  }
  &:focus {
    outline: none;
  }

  &--red {
    background-color: rgb(196, 49, 75);

    &:hover {
      background-color: rgb(196, 49, 75);
    }

    &:active {
      background-color: rgb(196, 49, 75) !important;
      box-shadow: none !important;
    }
  }
}

.no-style-button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;

  &:hover {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
  }

  &:active {
    outline: none;
    border: none;
  }

  button:focus {
    outline: 0;
  }
}
