*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Benton Sans";
  src: local("Benton Sans"),
    url("../../fonts/BentonSans UltraCondensed Regular.otf") format("opentype");
}

p title .title-card {
  background-color: $grey-background;
  font-family: "Benton Sans";
  font-size: 3vh !important;
}

.row-no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media (max-width: $phone-max-width) {
  .col-username {
    display: none;
  }
}
